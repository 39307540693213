import { createColumnHelper } from '@tanstack/react-table';
import {
  RenderSignal,
  RenderError,
  RenderStatus,
  RenderButtons,
  RenderHeaderWithTooltip,
  RenderGateway,
  RenderDate,
  RenderUserName
} from '../../../components/ReactTable/renderFunctions';
import { getExpandedIcon } from '../../../components/ReactTableV2';
import i18n from '../../../i18n';

const columnHelper = createColumnHelper();

const columns = (myRoleType) => [
  columnHelper.display({
    id: 'expander',
    enableResizing: false,
    header: () => null,
    cell: ({ row }) => getExpandedIcon(row),
    size: 46,
    meta: {
      thClasses: 'expander p-0',
      tdClasses: 'expander p-0'
    }
  }),
  columnHelper.accessor('gateway.sm_id', {
    id: 'sm_id',
    header: i18n.t('SMID'),
    size: 180,
    cell: (d) => RenderGateway({ value: d.getValue() }),
    meta: {
      tdClasses: 'sm_id'
    },
    enableSorting: false
  }),
  columnHelper.accessor(
    (row) => `${row.first_name} ${row.last_name} ${row.status} ${row.email}`,
    {
      id: 'last_name',
      header: i18n.t('fName'),
      size: 180,
      cell: ({ row }) => RenderUserName(row)
    }
  ),
  columnHelper.accessor('gateway.signal', {
    id: 'signal',
    header: RenderHeaderWithTooltip({
      headerText: i18n.t('signal'),
      tooltipText: i18n.t('signalTooltip'),
      placement: 'right-start'
    }),
    size: 170,
    cell: (d) => RenderSignal({ value: d.getValue() })
  }),
  columnHelper.accessor('error', {
    id: 'hasErrors',
    header: i18n.t('error'),
    size: 100,
    cell: ({ row }) => RenderError(row)
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('accStatus'),
    size: 169,
    cell: (d) => RenderStatus({ value: d.getValue() }),
    meta: {
      tdClasses: 'acc_status-td'
    },
    enableSorting: false
  }),
  columnHelper.accessor('last_login', {
    id: 'last_login',
    header: i18n.t('lastLogin'),
    size: 107,
    cell: (d) => RenderDate({ value: d.getValue() }),
    meta: {
      tdClasses: 'last_login-td'
    },
    enableSorting: false
  }),
  columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    enableSorting: false,
    size: 120,
    meta: {
      thClasses: 'text-right',
      tdClasses: 'justify-content-end'
    },
    cell: (d) => RenderButtons(d.row.original, {
      devices: { preLink: '/users/' },
      editUser: { preLink: '/setting-profile/solar-managers-users/' },
      remove: myRoleType === 'root' || myRoleType === 'solar_admin'
    })
  })
].filter(Boolean);

export default columns;
