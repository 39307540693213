// eslint-disable-next-line import/no-extraneous-dependencies
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import {
  RenderHeaderWithTooltip,
  RenderSwitcher,
  RenderLicense,
  RenderPaidLicense,
  RenderSingleButton,
  RenderRebootDropdown,
  RenderSignal,
  RenderError,
  RenderButtons
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';
import { DATA_LIST_ID } from './constants';
import { getCheckbox } from '../../components/ReactTableV2';

const columnHelper = createColumnHelper();

const columns = (myRoleType, { width }) => ([
  {
    id: 'select-col',
    enableResizing: false,
    header: ({ table }) => getCheckbox({
      checked: table.getIsAllRowsSelected(),
      indeterminate: table.getIsSomeRowsSelected(),
      onChange: table.getToggleAllRowsSelectedHandler()
    }),

    cell: ({ row }) => getCheckbox({
      checked: row.getIsSelected(),
      disabled: !row.getCanSelect(),
      indeterminate: row.getIsSomeSelected(),
      onChange: row.getToggleSelectedHandler()
    }),
    meta: {
      thClasses: 'select-col p-0',
      tdClasses: 'select-col p-0'
    }
  },
  columnHelper.accessor('sm_id', {
    id: 'sm_id',
    header: i18n.t('SMID'),
    size: width.sm_id,
    meta: {
      tdClasses: 'sm_id'
    }
  }),
  columnHelper.accessor('hardware', {
    id: 'hardware',
    header: i18n.t('hardware'),
    size: width.hardware
  }),
  columnHelper.accessor('firmware', {
    id: 'firmware',
    header: i18n.t('firmwareVersion'),
    size: width.firmware
  }),
  columnHelper.accessor('initialized', {
    id: 'initialized',
    header: i18n.t('initializedDate'),
    size: width.initialized,
    cell: (d) => (d.getValue() ? moment(d.getValue()).format('DD.MM.YYYY HH:mm') : '')
  }),
  columnHelper.accessor('signal', {
    id: 'signal',
    header: RenderHeaderWithTooltip({
      headerText: i18n.t('signal'),
      tooltipText: i18n.t('signalTooltip'),
      placement: 'right-start'
    }),
    size: width.signal,
    cell: (d) => RenderSignal({ value: d.getValue() })
  }),
  columnHelper.accessor('error', {
    id: 'hasErrors',
    header: i18n.t('error'),
    size: width.hasErrors,
    cell: ({ row }) => RenderError(row)
  }),
  columnHelper.accessor((row) => ({
    licenseId: row.owner?.license?.licenseCode,
    licenseTitle: row.owner.license?.name || '',
    userId: row.owner._id,
    hide: !row.owner,
    parentListId: DATA_LIST_ID
  }), {
    id: 'license',
    header: i18n.t('License'),
    size: width.license,
    cell: (d) => (d.row.original.owner?.license?.type === 'payment' ? RenderPaidLicense(d, myRoleType) : RenderLicense(d))
  }),
  columnHelper.accessor('releaseGroup', {
    id: 'releaseGroup',
    header: i18n.t('releaseGroup'),
    size: width.releaseGroup
  }),
  columnHelper.display({
    id: 'debug_logging',
    header: i18n.t('debugLogging'),
    size: width.debug_logging,
    cell: ({ row }) => RenderSwitcher(row),
    meta: {
      tdClasses: 'switcher-logging'
    }
  }),
  columnHelper.display({
    id: 'rebootReqBtn',
    header: i18n.t('rebootRequest'),
    size: width.rebootReqBtn,
    cell: ({ row }) => RenderRebootDropdown(row),
    meta: {
      tdClasses: 'rebootReqBtn'
    }
  }),
  columnHelper.display({
    id: 'removeDevice',
    header: i18n.t('removeDevice'),
    size: width.removeDevice,
    cell: ({ row }) => RenderSingleButton({ row, title: 'removeDevice' })
  }),
  columnHelper.display({
    id: 'removeFirmware',
    header: i18n.t('removeFirmware'),
    size: width.removeFirmware,
    cell: ({ row }) => RenderSingleButton({ row, title: 'removeFirmware' })
  }),
  columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    size: 80,
    meta: {
      thClasses: 'd-flex justify-content-end',
      tdClasses: 'justify-content-end'
    },
    cell: (d) => (d.row.original.owner?._id ? RenderButtons(
      { _id: d.row.original.owner?._id },
      { exportOneUser: true, devices: { preLink: '/users/' } }
    ) : '')
  })
]);

export default columns;
