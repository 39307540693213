import { createColumnHelper } from '@tanstack/react-table';
import {
  RenderInputDeviceName,
  RenderLoadGuardDescription,
  RenderInputDeviceFunction,
  RenderAffectedDevices,
  RenderButtons
} from '../../../components/ReactTable/renderFunctions';
import i18n from '../../../i18n';

const columnHelper = createColumnHelper();

const fullAccessRoles = [
  'root',
  'solar_admin',
  'property_management',
  'oem',
  'pv_installer',
  'pv_installer_employee',
  'pv_installer_employee_read_install',
  'oem_employee'
];

/**
 * Creates load-guard columns for devices table
 * @memberof module:UserDevices
 * @param {string} myRoleType Role type of user who is viewing
 */
const loadGuard = (myRoleType, isReadOnly, width = {}, userId = null) => [
  columnHelper.display({
    enableResizing: false,
    id: 'empty',
    header: '',
    size: 35
  }),
  columnHelper.accessor('inputDevice', {
    id: 'inputDevice',
    header: i18n.t('inputDevice'),
    size: width.inputDevice,
    cell: ({ row }) => RenderInputDeviceName(row),
    enableSorting: false
  }),
  columnHelper.accessor('description', {
    id: 'description',
    header: i18n.t('description'),
    size: width.description,
    cell: ({ row }) => RenderLoadGuardDescription(row),
    enableSorting: false
  }),
  columnHelper.accessor('inputDeviceFunction', {
    id: 'inputDeviceFunction',
    header: i18n.t('function'),
    size: width.inputDeviceFunction,
    cell: ({ row }) => RenderInputDeviceFunction(row),
    enableSorting: false
  }),
  columnHelper.accessor('affectedDevices', {
    id: 'affectedDevices',
    header: i18n.t('affectedDevices'),
    size: width.affectedDevices,
    cell: ({ row }) => RenderAffectedDevices(row),
    enableSorting: false
  }),
  !['end_user'].includes(myRoleType)
      && columnHelper.display({
        id: 'buttons',
        header: '',
        enableResizing: false,
        size: !isReadOnly ? width.buttons : undefined,
        meta: {
          thClasses: 'd-flex justify-content-end',
          tdClasses: 'justify-content-end'
        },
        cell: ({ row }) => {
          const additionalConditionFoRemoveByType = {
            feed_in: { preLink: '/production-regulations' }
          };

          return RenderButtons(row.original, {
            edit:
              fullAccessRoles.includes(myRoleType) && !isReadOnly && row.original.type === 'feed_in'
                ? { preLink: `/feed-in-management/edit/${userId}/` }
                : false,
            editRow:
              fullAccessRoles.includes(myRoleType)
              && !isReadOnly
              && row.original.type !== 'feed_in',
            removeDevice:
              fullAccessRoles.includes(myRoleType)
              && !isReadOnly
              && (additionalConditionFoRemoveByType[row.original.type] || true)
          });
        }
      })
].filter(Boolean);

export default loadGuard;
