import { createColumnHelper } from '@tanstack/react-table';
import {
  RenderSignal,
  RenderButtons,
  RenderDevicesError,
  RenderDevicesErrorDetails,
  RenderSensorData
} from '../../../components/ReactTable/renderFunctions';
import i18n from '../../../i18n';
import { getExpandedIcon } from '../../../components/ReactTableV2';

const columnHelper = createColumnHelper();

/**
 * Creates smart-meter columns for devices table
 * @memberof module:UserDevices
 * @param {string} myRoleType Role type of user who is viewing
 */
const subMeters = (myRoleType, isReadOnly, width = {}, columns = {}) => {
  const isAllowEdit = [
    'root',
    'solar_admin',
    'property_management',
    'oem',
    'pv_installer',
    'pv_installer_employee',
    'pv_installer_employee_read_install',
    'oem_employee',
    'end_user',
    'viewer'
  ].includes(myRoleType) && !isReadOnly;

  return ([
    columnHelper.display({
      id: 'expander',
      enableResizing: false,
      header: () => null,
      cell: ({ row }) => getExpandedIcon(row),
      size: 46,
      meta: {
        thClasses: 'expander p-0',
        tdClasses: 'expander p-0'
      }
    }),
    columnHelper.accessor('device_group', {
      id: 'device_group',
      header: '',
      size: width.device_group,
      cell: (d) => i18n.t(d.getValue()),
      enableSorting: false
    }),
    columnHelper.accessor('tag.name', {
      id: 'tag',
      header: '',
      size: width.tag,
      enableSorting: false
    }),
    columns.ip && columnHelper.accessor('ip', {
      id: 'ip',
      header: '',
      size: width.ip,
      enableSorting: false
    }),
    columns.mac && columnHelper.accessor('mac', {
      id: 'mac',
      header: '',
      size: width.mac,
      enableSorting: false
    }),
    columns.signal && columnHelper.accessor('signal', {
      id: 'signal',
      header: '',
      size: width.signal,
      cell: (d) => RenderSignal({ value: d.getValue() }),
      enableSorting: false
    }),
    columns.errorcodes && columnHelper.accessor('errorcodes', {
      id: 'errorcodes',
      header: '',
      size: width.errorcodes,
      cell: ({ row }) => RenderDevicesError(row),
      enableSorting: false
    }),
    !['end_user'].includes(myRoleType) && columns.errorDetails && columnHelper.accessor('errorDetails', {
      id: 'errorDetails',
      header: '',
      size: width.errorDetails,
      cell: ({ row }) => RenderDevicesErrorDetails(row),
      enableSorting: false
    }),
    columnHelper.accessor('sensors_data', {
      id: 'sensors_data',
      header: '',
      size: 60,
      cell: ({ row }) => RenderSensorData(row),
      enableResizing: false,
      enableSorting: false
    }),
    columnHelper.display({
      id: 'buttons',
      header: '',
      enableResizing: false,
      size: !isReadOnly ? width.buttons : undefined,
      meta: {
        thClasses: 'text-right',
        tdClasses: 'justify-content-end'
      },
      cell: (d) => RenderButtons(
        d.row.original,
        {
          editRow: isAllowEdit,
          removeDevice: isAllowEdit,
          newType: 'sub-meter'
        }
      )
    })
  ]).filter(Boolean);
};

export default subMeters;
