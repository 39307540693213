import { createColumnHelper } from '@tanstack/react-table';
import {
  RenderStatus,
  RenderButtons,
  RenderDate
} from '../../../components/ReactTable/renderFunctions';
import { getExpandedIcon } from '../../../components/ReactTableV2';
import i18n from '../../../i18n';

const columnHelper = createColumnHelper();

const columns = (myRoleType) => ([
  columnHelper.display({
    id: 'expander',
    enableResizing: false,
    header: () => null,
    cell: ({ row }) => getExpandedIcon(row),
    size: 46,
    meta: {
      thClasses: 'expander p-0',
      tdClasses: 'expander p-0'
    }
  }),
  columnHelper.accessor('my_company.name', {
    id: 'my_company',
    header: i18n.t('company'),
    size: 180,
    cell: (d) => d.getValue() || 'No company'
  }),
  !['oem', 'oem_employee'].includes(myRoleType) && columnHelper.accessor('oems', {
    id: 'oems',
    header: i18n.t('oem'),
    size: 160,
    cell: (d) => d.getValue().map((oem) => oem.name).join(', ')
  }),
  columnHelper.accessor('city', {
    id: 'city',
    header: i18n.t('city'),
    size: 160,
    meta: {
      tdClasses: 'city-td'
    }
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('accStatus'),
    size: 169,
    cell: (d) => RenderStatus({ value: d.getValue() }),
    meta: {
      tdClasses: 'acc_status-td'
    },
    enableSorting: false
  }),
  columnHelper.accessor('last_login', {
    id: 'last_login',
    header: i18n.t('lastLogin'),
    size: 107,
    cell: (d) => RenderDate({ value: d.getValue() }),
    meta: {
      tdClasses: 'last_login-td'
    },
    enableSorting: false
  }),
  columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    enableSorting: false,
    size: 120,
    meta: {
      thClasses: 'text-right',
      tdClasses: 'justify-content-end'
    },
    cell: (d) => RenderButtons(d.row.original, {
      view: { preLink: '/pv-installers/' },
      editUser: { preLink: '/setting-profile/pv-installer-users/' },
      delete: (myRoleType === 'root' || myRoleType === 'solar_admin' || myRoleType === 'property_management')
    })
  })
]).filter(Boolean);

export default columns;
