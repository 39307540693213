import { createColumnHelper } from '@tanstack/react-table';
import {
  RenderSignal,
  RenderButtons,
  RenderDevicesError,
  RenderHeaderWithTooltip,
  RenderDateAndTime,
  RenderSoc
} from '../../../components/ReactTable/renderFunctions';
import i18n from '../../../i18n';
import { getExpandedIcon } from '../../../components/ReactTableV2';

const columnHelper = createColumnHelper();

/**
 * Creates smart-meter columns for devices table
 * @memberof module:UserDevices
 * @param {string} myRoleType Role type of user who is viewing
 */
const cars = (myRoleType, isReadOnly, width = {}) => {
  const isAllowEditAndDelete = [
    'root',
    'solar_admin',
    'property_management',
    'oem',
    'pv_installer',
    'viewer',
    'pv_installer_employee',
    'pv_installer_employee_read_install',
    'oem_employee',
    'end_user'
  ].includes(myRoleType);

  return ([
    columnHelper.display({
      id: 'expander',
      enableResizing: false,
      header: () => null,
      cell: ({ row }) => getExpandedIcon(row),
      size: 46,
      meta: {
        thClasses: 'expander p-0',
        tdClasses: 'expander p-0'
      }
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: i18n.t('name'),
      size: width.name,
      cell: (d) => i18n.t(d.getValue()),
      enableSorting: false
    }),
    columnHelper.accessor('signal', {
      id: 'signal',
      header: i18n.t('signal'),
      size: width.signal,
      cell: (d) => RenderSignal({ value: d.getValue() }),
      enableSorting: false
    }),
    columnHelper.accessor('remainingDistance', {
      id: 'remainingDistance',
      header: i18n.t('remainingDistance'),
      size: width.remainingDistance,
      cell: (d) => (d.getValue() ? `${d.getValue()}km` : ''),
      enableSorting: false
    }),
    columnHelper.accessor('soc', {
      id: 'soc',
      header: i18n.t('batterySoc'),
      size: width.soc,
      cell: (d) => RenderSoc({ value: d.getValue() }),
      enableSorting: false
    }),
    columnHelper.accessor('lastUpdate', {
      id: 'lastUpdate',
      header: i18n.t('lastUpdate'),
      size: width.lastUpdate,
      cell: (d) => RenderDateAndTime({ value: d.getValue() }),
      enableSorting: false
    }),
    columnHelper.accessor('errorcodes', {
      id: 'errorcodes',
      header: RenderHeaderWithTooltip({
        headerText: i18n.t('error'),
        tooltipText: i18n.t('errorsHintTooltip'),
        placement: 'right-start',
        style: {
          verticalAlign: 'middle',
          marginLeft: '6px'
        }
      }),
      size: width.errorcodes,
      cell: ({ row }) => RenderDevicesError(row),
      enableSorting: false
    }),
    isAllowEditAndDelete && columnHelper.display({
      id: 'buttons',
      header: '',
      enableResizing: false,
      size: !isReadOnly ? width.buttons : undefined,
      meta: {
        thClasses: 'text-right',
        tdClasses: 'justify-content-end'
      },
      cell: (d) => RenderButtons(
        d.row.original,
        {
          editRow: isAllowEditAndDelete && !isReadOnly,
          removeDevice: isAllowEditAndDelete && !isReadOnly
        }
      )
    })
  ]).filter(Boolean);
};

export default cars;
