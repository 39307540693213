// eslint-disable-next-line import/no-extraneous-dependencies
import { createColumnHelper } from '@tanstack/react-table';
import { getExpandedIcon } from '../../components/ReactTableV2';
import {
  RenderDate,
  RenderStatus,
  RenderButtons
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columnHelper = createColumnHelper();

export const columnsV2 = (myRoleType, { width }) => ([
  columnHelper.display({
    id: 'expander',
    enableResizing: false,
    header: () => null,
    cell: ({ row }) => getExpandedIcon(row),
    size: 60,
    meta: {
      thClasses: 'expander p-0',
      tdClasses: 'expander p-0'
    }
  }),

  columnHelper.accessor('my_company', {
    id: 'my_company',
    header: i18n.t('company'),
    cell: (d) => d.getValue()?.name ?? 'No company',
    size: width.my_company
  }),

  ...(!['oem', 'oem_employee'].includes(myRoleType) ? [columnHelper.accessor('oems', {
    id: 'oems',
    header: i18n.t('oem'),
    cell: (d) => d.getValue().map((oem) => oem.name).join(', '),
    size: width.oems
  })] : []),

  columnHelper.accessor('city', {
    id: 'city',
    header: i18n.t('city'),
    size: width.city
  }),

  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('accStatus'),
    cell: (d) => RenderStatus({ value: d.getValue() }),
    size: width.status
  }),

  columnHelper.accessor('last_login', {
    id: 'last_login',
    header: i18n.t('lastLogin'),
    cell: (d) => RenderDate({ value: d.getValue() }),
    size: width.last_login
  }),

  columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    meta: {
      width: 'auto',
      thClasses: 'flex-fill d-flex justify-content-end',
      tdClasses: 'flex-fill d-flex justify-content-end'
    },
    cell: (d) => RenderButtons(
      d.row.original,
      {
        devices: false,
        editUser: { preLink: '/setting-profile/pv-installer-users/' },
        view: { preLink: '/pv-installers/' },
        remove: (myRoleType === 'root' || myRoleType === 'solar_admin')
      }
    )
  })
]);
