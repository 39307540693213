import { createColumnHelper } from '@tanstack/react-table';
import {
  RenderStatus,
  RenderButtons,
  RenderDate,
  RenderAccess,
  RenderEmail,
  RenderFullName
} from '../../components/ReactTable/renderFunctions';
import i18n from '../../i18n';

const columnHelper = createColumnHelper();

const columns = () => [
  columnHelper.display({
    enableResizing: false,
    id: 'empty',
    header: '',
    size: 35
  }),
  columnHelper.accessor('last_name', {
    id: 'last_name',
    header: i18n.t('fName'),
    cell: (d) => RenderFullName(d.row),
    size: 180
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: i18n.t('mail'),
    cell: (d) => RenderEmail({ value: d.getValue() }),
    size: 225,
    meta: {
      tdClasses: 'email-text-td'
    }
  }),
  columnHelper.accessor('city', {
    id: 'city',
    header: i18n.t('city'),
    size: 160,
    meta: {
      tdClasses: 'city-td'
    }
  }),
  columnHelper.accessor('status', {
    id: 'status',
    header: i18n.t('accStatus'),
    size: 169,
    cell: (d) => RenderStatus({ value: d.getValue() }),
    meta: {
      tdClasses: 'acc_status-td'
    },
    enableSorting: false
  }),
  columnHelper.accessor('access', {
    id: 'access',
    header: i18n.t('access'),
    size: 100,
    cell: ({ row }) => RenderAccess(row),
    enableSorting: false
  }),
  columnHelper.accessor('last_login', {
    id: 'last_login',
    header: i18n.t('lastLogin'),
    size: 107,
    cell: (d) => RenderDate({ value: d.getValue() }),
    meta: {
      tdClasses: 'last_login-td'
    },
    enableSorting: false
  }),
  columnHelper.display({
    id: 'buttons',
    header: '',
    enableResizing: false,
    enableSorting: false,
    size: 120,
    meta: {
      thClasses: 'text-right',
      tdClasses: 'justify-content-end'
    },
    cell: (d) => RenderButtons(d.row.original, {
      editUser: { preLink: '/setting-profile/oem-employee/' },
      remove: true
    })
  })
].filter(Boolean);

export default columns;
